import { render, staticRenderFns } from "./modalMessageInfo.vue?vue&type=template&id=419d990c&scoped=true&"
import script from "./modalMessageInfo.vue?vue&type=script&lang=js&"
export * from "./modalMessageInfo.vue?vue&type=script&lang=js&"
import style0 from "./modalMessageInfo.vue?vue&type=style&index=0&id=419d990c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "419d990c",
  null
  
)

export default component.exports