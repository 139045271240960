<template>
    <section class="almacen-materiales px-3 container-fluid overflow-auto custom-scroll">
        <titulo-divisor titulo="Lista de materiales">
            <div class="row">
                    <!-- importar excel -->
                <div class="col-auto icon-option my-auto px-1">
                    <el-tooltip content="Importar excel con lista de materiales" placement="bottom" effect="light" visible-arrow>
                        <i class="icon-upload-outline f-26 cr-pointer"
                            :class="permitAction('almacen.materiales.importar.materiales') ? 'cr-pointer' : 'cr-not-allowed'"
                            @click="functionPermitAction('almacen.materiales.importar.materiales', openModalImportMaterials)"
                        />
                    </el-tooltip>
                </div>
                <!-- descargar  -->
                <div class="col-auto icon-option my-auto px-1 mb-1" style="transform:rotate(180deg) !important">
                    <el-tooltip content="Descargar plantilla" placement="bottom" effect="light" visible-arrow
                        :class="permitAction('almacen.etapa.descargar.plantilla.configuracion') ? 'cr-pointer' : 'cr-not-allowed'"
                        @click="functionPermitAction('almacen.etapa.descargar.plantilla.configuracion')"
                    >
                        <a v-if="permitAction('almacen.etapa.descargar.plantilla.configuracion')" href="https://v2-fullsmart-iaf.s3.amazonaws.com/iaf-software/almacen/materiales/plantilla.xlsx" 
                        class="icon-upload-outline download_template f-26 cr-pointer"
                        download="Plantilla materiales"
                        />
                        <p v-else class="icon-upload-outline download_template f-26 cr-not-allowed"></p>
                    </el-tooltip>
                </div>
                <!-- exportar  -->
                <div class="col-auto my-auto px-1 mb-1 icon-option">
                    <el-tooltip content="Descargar datos" effect="light" visible-arrow>
                        <a href="#" class="text-light-color br-4 download_template" @click.prevent="ExportarExcel">
                            <i class="icon-arrow-down-bold f-23"></i>
                        </a>
                    </el-tooltip>
                </div>
                <div class="col auto">
                    <input-search v-model="query" :search="beforeSearch" auto-search placeholder="Buscar" size="small" />
                </div>
                <div class="col-auto my-auto px-1">
                    <button class="btn btn-general f-12 px-4"
                        :class="permitAction('almacen.materiales.crear') ? 'cr-pointer' : 'cr-not-allowed'"
                        @click="functionPermitAction('almacen.materiales.crear', crearMaterial)"
                    >Crear material</button>
                </div>
                <div v-if="filterCount == 0" class="col-auto my-auto pl-1 icon-option">
                    <el-tooltip content="Filtro" placement="bottom" effect="light" visible-arrow>
                        <button class="btn btn-square32-5d ml-2 cr-pointer" @click="abrirModalFiltros">
                            <i class="icon-filter text-white" />
                        </button>
                    </el-tooltip>
                </div>
                <div v-if="filterCount > 0" class="col-auto filtro-contador my-auto pl-1">
                    <div class="d-flex border-general br-4">
                        <button class="btn btn-square32-5d cr-pointer" @click="abrirModalFiltros">
                            <i class="icon-filter text-white" />
                        </button>
                        <div class="d-middle px-2">
                            <p class="f-12">{{filterCount}}</p>
                            <i class="icon-close-circle f-11 text-muted pl-1" @click="reload" />
                        </div>
                    </div>
                </div>
            </div>
        </titulo-divisor>
        <tabla-general :mostrarBuscador="false" alturaTabla="calc(100vh - 400px)" usar-servidor :servidor-data="materials" @paginar="getMaterials">
            <el-table-column label="Ref" prop="ref" width="100">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2 tres-puntos">{{ scope.row.referencia }}</p>
                </template>
            </el-table-column>
            <el-table-column label="Nombre" prop="nombre">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2 tres-puntos cr-pointer f-600" @click="viewMaterialInfo(scope.row.id)">
                        {{ scope.row.nombre || '-'}}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Tipo" prop="tipo" width="100">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2">
                        {{ scope.row.tipo }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="U.M." prop="um" width="80">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2">
                        {{ scope.row.unidad_medida }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Valor cotizado" prop="valorCotizado" width="120" align="center">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2">
                        {{ formatoMoneda(scope.row.valor) }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Etapas" prop="numEtapas" width="100" align="center">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2">
                        {{ scope.row.etapas}}
                    </p>
                </template>
            </el-table-column>
            <el-table-column prop="planMantenimiento" width="120" align="center">
                <template slot="header">
                    <p class="lh-15">Planes de mantenimiento</p>
                </template>
                <template slot-scope="scope">
                    <p>{{ scope.row.planes_mantenimiento ? scope.row.planes_mantenimiento : '-'}}</p>
                </template>
            </el-table-column>
            <el-table-column label="Estado" width="70" align="center">
                <template slot-scope="scope">
                    <i :class="`icon-circle-fill f-20 ${scope.row.estado === 0  ? 'state__inactive' : 'state__active'} `" />
                </template>
            </el-table-column>
            <el-table-column prop="proveedorFavorito" width="120" align="center">
                <template slot="header">
                    <p class="lh-15">Proveedor favorito</p>
                </template>
                <template slot-scope="scope">
                    <el-tooltip placement="left" effect="light" visible-arrow>
                        <div slot="content" style="max-width: 300px;">
                            <p class="mb-1 f-600">{{ scope.row.proveedor_favorito ? scope.row.proveedor_favorito.nombre : '' }}</p>
                            <p class="f-11">{{ scope.row.proveedor_favorito ? scope.row.proveedor_favorito.updated_at : ''  | helper-fecha('DD/MM/YYYY') }}</p>
                        </div>
                        <img v-if="scope.row.id_proveedor_favorito != null" :src="scope.row.proveedor_favorito ? scope.row.proveedor_favorito.imagen : '' | helper-storage" alt="" width="35" height="35" class="rounded-circle border">
                    </el-tooltip>
                    <p v-if="scope.row.id_proveedor_favorito == null" class="f-11"> -- </p>
                </template>
            </el-table-column>
        </tabla-general>
        <!-- partials  -->
        <modal-lateral ref="abrirModalFiltros" titulo="Filtro">
            <div class="row mx-0 justify-content-center my-2">
                <div class="col-11 my-4">
                    <p class="input-label-top">Tipo de material</p>
                    <el-select v-model="filter.id_tipo_material" clearable placeholder="Seleccionar material" size="small" class="w-100">
                        <el-option v-for="item in select_material_types" :key="item.id" :label="item.tipo_material" :value="item.id" />
                    </el-select>
                </div>
                <div class="col-11 my-4">
                    <p class="input-label-top">Unidad de medida</p>
                    <el-select v-model="filter.id_unidad_medida" clearable placeholder="Seleccionar U.M" size="small" class="w-100">
                        <el-option v-for="item in select_measure_units" :key="item.id" :label="`${item.unidad_medida} (${item.sigla})`" :value="item.id" />
                    </el-select>
                </div>
                <div class="col-11 my-4">
                    <p class="input-label-top">Rango de valor</p>
                    <el-slider v-model="range_value" range :min="range[0]" :max="range[1]" :format-tooltip="formatTooltipRange" :disabled="disableSlider" />
                </div>
                <div class="col-11 my-4">
                    <p class="input-label-top">Estado del material</p>

                    <el-checkbox-group v-model="filter.estado_material" class="mt-1" fill="#0B56A7">
                        <el-checkbox :label="1">Activo</el-checkbox>
                        <el-checkbox :label="0">Inactivo</el-checkbox>
                    </el-checkbox-group>
                </div>
            </div>
            <div class="botones">
                <button type="button" class="btn mx-2 btn-limpiar f-12 h-32px" @click="reload"> Limpiar </button>
                <button type="button" class="btn mx-2 btn-general w-80px f-12 h-32px text-white" @click="filtrarMateriales"> Filtrar </button>
            </div>
        </modal-lateral>
        <modal-check-file-materials ref="modalCheckFileMaterials" @reload="reload" @uploadFileAgain="openModalImportMaterials" />
        <modal-upload-file-materials ref="modalUploadFileMaterials" @checkMaterials="openModalCheckMaterials" />
        <modal-message-info ref="openModalMessageInfo" />
    </section>
</template>

<script>
import axios from 'axios'
import { mapActions, mapGetters } from 'vuex';
import modalCheckFileMaterials from './partials/modalCheckFileMaterials.vue'
import modalUploadFileMaterials from './partials/modalUploadFileMaterials.vue'
import modalMessageInfo from './partials/modalMessageInfo.vue'

export default {
    components:{
                modalCheckFileMaterials,
                modalUploadFileMaterials,
                modalMessageInfo,
            },
    data(){
        return{
            buscarListado: '',
            inputCantUnidadesTable: '',
            inputCantUnidadesTable: '',
            listadoPlanMantenimiento: '',
            valueSelect: '',
        }
    },
    computed: {
        ...mapGetters({
			user: 'auth/user',
            materials: 'almacen/materiales/materials',
            select_material_types: 'selects/selects/select_material_types',
            select_measure_units: 'selects/selects/select_measure_units',
            range: 'almacen/materiales/range',
        }),
        filter:{
            get(){
                return this.$store.getters['almacen/materiales/filter'];
            },
            set(val){
                this.$store.commit('almacen/materiales/set_filter', val)
            }
        },
        query: {
            get(){
                return this.$store.getters['almacen/materiales/query']
            },
            set(val){
                this.$store.commit('almacen/materiales/set_query', val)
            }
        },
        range_value:{
            get(){
                return [this.filter.valor_minimo, this.filter.valor_maximo]
            },
            set(val){
                this.filter.valor_minimo = val[0]
                this.filter.valor_maximo = val[1]
            },
        },
        filterCount(){
            return this.filter.count();
        },
        disableSlider(){
            return this.range.every(el => el === 0)
        }
    },
    async created(){
        this.getBreadcumbs([ 'almacen.main', 'almacen.materiales' ]);
        this.getSelects(['Action_get_select_material_types', 'Action_get_select_measure_units'])
        this.query = ''
        await this.getMaterialsRange()
        await this.getMaterials()
		this.removerLocalStorage()
    },
    methods:{
        ...mapActions({
            getMaterials: 'almacen/materiales/Action_get_materials',
            getMaterialsRange: 'almacen/materiales/Action_get_materials_range',
            getBreadcumbs: 'navigation/breadcumbs/getBreadcumbs',
            clearFilter: 'almacen/materiales/clear_filter',
            getSelects: 'selects/selects/sync',
        }),
        async ExportarExcel() {
            try{
                await axios.post(`/almacen/exportar-materiales`,  {...this.filter, buscar: this.query },{
                    responseType : "blob",
                })
                    .then(response => {
                        console.log("🚀 ~ file: index.vue:261 ~ ExportarExcel ~ link:", response)
                        const url = URL.createObjectURL(new Blob([response.data]))
                        const link = document.createElement('a')
                        link.setAttribute('download','materiales_export.xlsx')
                        link.href = url
                        document.body.appendChild(link);
                        link.click()
                    })
                    .catch(err => {
                        console.log(err);
                    })

            }catch(e){
                console.log(e);
            }
        },
        openModalCheckMaterials(){
            this.$refs.openModalMessageInfo.toggle()

            setTimeout(() => {
                this.$refs.openModalMessageInfo.toggle()
				this.$refs.modalCheckFileMaterials.toggle()
            }, 1200);
        },
		removerLocalStorage(){
			localStorage.removeItem('mat_payload')
		},
        openModalImportMaterials(){
			this.removerLocalStorage()
            this.$refs.modalUploadFileMaterials.toggle()
        },
        reload(){
            this.clearFilter()
            this.getMaterials();
        },
        crearMaterial(){
            this.$router.push({ name: 'almacen.materiales.crear' })
        },
        async viewMaterialInfo(id){
            this.$router.push({ name: 'almacen.materiales.ver', params: { id } })
        },
        abrirModalFiltros(){
            this.$refs.abrirModalFiltros.toggle()
        },
        beforeSearch(){
            this.getMaterials();
        },
        formatTooltipRange(val){
            return this.formatoMoneda(val)
        },
        async filtrarMateriales(){
            await this.getMaterials();
            this.$refs.abrirModalFiltros.toggle();
        }
    }
}
</script>

<style lang="scss" scoped>
.almacen-materiales{
    height: calc(100vh - 240px);
}
.download_template{
        color: var(--color-5d);
        &:hover{
            text-decoration: none !important;
        }
    }
    .icon-option{
        .icon-plus-circle, .icon-text-box-plus-outline, .icon-upload-outline, .icon-trash-can-outline{
            &:hover{
                color: var(--color-general);
            }
        }
    }
</style>