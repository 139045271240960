<template>
    <modal ref="modalImportMaterials" titulo="Importar materiales" class="modal-status-updating-file">
        <div class="row mx-0 my-3 justify-content-center f-14 py-4">
            <div v-if="error_subiendo"  class="col-12 text-center">
                <i v-if="error_subiendo.error" class="border mx-auto border-danger d-middle-center icon-window-close rounded-circle text-danger wh-80 f-70" />
                <i v-else class="border mx-auto border-success d-middle-center icon-ok rounded-circle text-success wh-80 f-40" />
                <p class="mt-3 f-20">{{ error_subiendo.mensaje }}</p>
            </div>
        </div>
    </modal>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
    data(){
        return {
        }
    },
    computed: {
        ...mapGetters({
            error_subiendo: 'almacen/stages/error_subiendo',
        }),
    },
    methods: {
        ...mapMutations({
            prueba_excel: 'almacen/stages/prueba_excel',
        }),
        ...mapActions({
            Action_validate_excel: 'almacen/stages/Action_validate_excel',
        }),
        toggle(){
            this.$refs.modalImportMaterials.toggle()
        },
    }
}
</script>

<style lang="scss" scoped>
.f-70{
    font-size: 70px;
}
</style>
